<template>
<div>
  <Header></Header>
  <BGImg></BGImg>
  <div style="margin: 20px auto; max-width: 1000px;">
    <div class="title">视频中心</div>
    <div style="border-bottom: 1px solid #e1e1e1;width: 100%;height: 1px;margin-bottom: 18px;"></div>
    <div class="listcontainer">
      <VideoItem v-for="item in list" :item="item" @click.native="click(item.videoId)" style="cursor: pointer"></VideoItem>
    </div>
  </div>

  <Footer></Footer>
</div>
</template>

<script>
import VideoItem from "@/components/VideoItem";
import Header from "@/components/Header";
import BGImg from "@/components/BGImg";
import Footer from "@/components/Footer";
export default {
  name: "VideoList",
  components: {Footer, BGImg, Header, VideoItem},
  data(){
    return{
      list:[

      ]
    }
  },
  mounted() {
    this.axios.get('/api/video/user/list/').then(res=>{
      this.list = res.data;
    })
  },
  methods:{
    click(id){
      window.open('/video?id='+id);
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 28px;
  padding: 15px 0;
  text-align: left;
  font-weight: normal;
  color: #065aa9;
  line-height: 1.6;
  margin-top: 30px;
  margin-left: 20px;
}
.listcontainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>